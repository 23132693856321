import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() text: string;
  @Input() isWhite: boolean;
  @Input() noClickable: boolean;

  constructor() { }

  ngOnInit() {
    if (this.noClickable === undefined) {
      this.noClickable = false;
    }
  }

  
}

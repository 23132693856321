import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { config } from '../../config/config';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

    post(type: any, end_point: string, data?: any): Observable<any> {
        return this.http.request(type, `${environment.API_URL}` + end_point, data ? { body: data } : {})
    }

    get(end_point: string): Observable<any> {
        // console.log(`${type} ${environment.API_URL}` + end_point);
        // console.log(data)
        return this.http.get(`${environment.API_URL}` + end_point);
    }
  makeRequest(type: any, end_point: string, data?: any): Observable<any> {
    // console.log(`${type} ${environment.API_URL}` + end_point);
    // console.log(data);
    return this.http.request(type, `${environment.API_URL}` + end_point, data ? {body: data} : {})
        .pipe(map( res => {
          return res["data"];
        }, err => {
          console.error(err);
        }));
  }
}

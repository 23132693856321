import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router,RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit {
  title = 'app';
  showBackButton = true;

  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        switch (event.url) {
          case '/':
            this.showBackButton = false;
            break;
          case '/welcome':
            this.showBackButton = false;
            break;
          case '/success':
            this.showBackButton = false;
            break;
          default:
            this.showBackButton = true;
            break;
        }
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData['animation'];
  }

  goBack() {
    this.location.back();
  }

}

﻿export const texts = {
    title: `מי אנחנו?`,
    title_p_one: `נמאס לכם לקבל הצעות עבודה לא רלוונטיות בלינקדין ובמייל?
    לא מחפשים עבודה באופן אקטיבי, אבל כן חשוב לכם להיות בעניינים ולדעת ראשונים על התפקידים הכי שווים בשוק ההייטק הישראלי?`,
    title_p_two:'אם קלענו בול, הצטרפו לשירות שלנו בGotfriends שנבנה במיוחד עבורכם!',
    sub_titles: [
        `מה אנחנו מציעים?`,
        `אז למה אתם מחכים?`,
        `איך זה עובד?`
    ],
    how_does_it_works: `כל שנותר לעשות זה לשלוח קורות חיים ואנחנו כבר נדאג לכל השאר...`,
    button_text: `הבנתי, עכשיו מצאו לי משרה!`,
    offers: [
        `לשמוע באופן דיסקרטי על התפקידים המעולים ביותר בשוק, גם כשאתם עובדים, לא מחפשים עבודה באופן אקטיבי ואפילו מרוצים.`,
        `דרכנו תהיה לכם גישה למשרות הכי שוות בהייטק הישראלי!
        לחברות הייטק חדשניות, ולסטארטאפים מדליקים ולמשרות מתחת לרדאר.`,
        `לקבל מידע על המשרות שמתאימות בול לכם, לניסיון שצברתם ולאן שאתם רוצים להגיע בקריירה וכך למצוא את משרת החלומות שלכם.`,
        `נציע לכם אך ורק 5 הצעות עבודה מתאימות בול לרשימת הדרישות שלכם, תוך שמירה על דיסקרטיות ושקיפות מלאה לכל אורך התהליך.`,
        `לא תצטרכו להתאמץ ולחפש בעצמכם - אנחנו נשמש הסוכן האישי שלכם ונחסוך לכם רעשים ובזבוזי זמן מיותרים.`,
        `נלווה אתכם (עם חיוך) לאורך כל התהליך עד הסגירה המוצלחת, לרבות בשלב המו"מ על התנאים והשכר.`
    ],
    steps: [
        `מספרים לנו מה אתם מחפשים`,
        `מקבלים 5 הצעות עבודה למייל`,
        `מתחילים עבודה חדשה`
    ]
};

import { Component, OnInit } from '@angular/core';
import { texts } from './preference.texts';
import { ImageCheck } from '../../models/image-check';
import { ActivatedRoute } from '@angular/router';
import { LeadService } from '../../services/lead/lead.service';
import { GototopService } from '../../services/gototop/gototop.service';
import { environment } from '../../../environments/environment';
import { FooterNavService } from '../../services/footerNav/footerNav.service';
import { OptionText } from '../../models/option-text';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css']
})
export class PreferenceComponent implements OnInit {

  texts = texts;
  footerImages: ImageCheck[] = [];
  options: OptionText[] = [];
  locations: ImageCheck[] = [];
  companySize: ImageCheck[] = [];
  max: number;
  min: number;
  value: number;
  disableNextButton = true;

  constructor(private route: ActivatedRoute,
    private lead: LeadService,
    private scroll: GototopService,
    private footerImg: FooterNavService) {

  }

  ngOnInit() {
    this.max = environment.maxValueForSalary;
    this.min = environment.minValueForSalary;
    this.value = environment.startValueForSalary;
    this.route.data.subscribe((data: { OPTIONS: Array<OptionText> }) => {
      this.options = data.OPTIONS;
    });
    this.route.data.subscribe((data: { LOCATIONS: Array<ImageCheck> }) => {
      this.locations = data.LOCATIONS
    })
    this.route.data.subscribe((data: { COMPANYSIZE: Array<ImageCheck> }) => {
      console.log("companySize", data.COMPANYSIZE);
      this.companySize = data.COMPANYSIZE
    })
    this.footerImages = this.footerImg.getFooterImages();
    this.footerImg.moveToPrefencesPage();
    this.scroll.gotoTop();
  }

  calculateStyles() {
    let dis = 100 / (this.max - this.min) * (this.value - this.min);
    if (this.value == 100) {
      dis = 99;
    }
    return { 'right': dis + '%' };
  }

  change(event) {
    this.value = event.newValue;
    this.lead.setSalary(event.newValue);
  }

  trainOption = () => {
    this.lead.changeCloseToTrain();
  }

  Options = (option: OptionText) => {
    debugger;
    this.lead.pushPopOption(option);
  }

  setCompany = (comp: string) => {
    this.lead.pushPopCompanySize(comp);
    this.handleDisableNextBtn();
  }

  setLocations = (loc: string) => {
    this.lead.pushPopLocations(loc);
    this.handleDisableNextBtn();
  }

  handleDisableNextBtn() {
    this.disableNextButton = this.lead.isCompanySizeEmpty() || this.lead.isLocationsEmpty();
  }
  onChangeOption(event) {
    debugger;
    console.log("onChangeOption", event);
    this.options[event.target.id].details = event.target.value;
    this.lead.pushPopOption1();
    console.log("onChangeOption", this.options[event.target.id]);
  }
  FocusOption(option: OptionText, value: string) {
    const optionToPush = { ...option, details: value };
    this.lead.pushPopOption(optionToPush);
    console.log("FocusOption", { option, value });
    console.log('lead', this.lead);
  }
}


import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SocialLoginModule, AuthServiceConfig, LinkedInLoginProvider } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

import { LeadService } from './services/lead/lead.service';
import { HttpService } from './services/http/http.service';
import { GototopService } from './services/gototop/gototop.service'


import { AppRoutingModule } from "./app-routing.moudle";

import { AppComponent } from './app.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { WhoWeAreComponent } from './pages/who-we-are/who-we-are.component';
import { JobsYouAreLookingForComponent } from './pages/jobs-you-are-looking-for/jobs-you-are-looking-for.component';
import { InternshipsComponent } from './pages/internships/internships.component';
import { PreferenceComponent } from './pages/preference/preference.component';
import { AlmostDoneComponent } from './pages/almost-done/almost-done.component';
import { FillDetailsComponent } from './pages/fill-details/fill-details.component';
import { SuccessComponent } from './pages/success/success.component';
import { ComponentsModule } from './components/components.module';
import { environment } from '../environments/environment';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleLoginProvider)
    },
    {
    id: LinkedInLoginProvider.PROVIDER_ID,
    provider: new LinkedInLoginProvider(environment.linkedInLoginProvider)
    }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    WhoWeAreComponent,
    JobsYouAreLookingForComponent,
    InternshipsComponent,
    PreferenceComponent,
    AlmostDoneComponent,
    FillDetailsComponent,
    SuccessComponent
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgxBootstrapSliderModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    AppRoutingModule,
    ComponentsModule
  ],
  providers: [
    LeadService,
    HttpService,
    GototopService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

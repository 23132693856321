import { ImageCheck } from '../../models/image-check';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { texts } from './welcome.texts';
import { GototopService } from '../../services/gototop/gototop.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  texts = texts;

  constructor(private router: Router,
              private scroll:GototopService) { }

  ngOnInit() {
    this.scroll.gotoTop();
  }
}

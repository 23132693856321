import { Component, OnInit } from '@angular/core';
import { texts } from './almost-done.texts';
import { Router } from '@angular/router';
import { GototopService } from '../../services/gototop/gototop.service';
import { LeadService } from '../../services/lead/lead.service';
import { FooterNavService } from '../../services/footerNav/footerNav.service';

@Component({
  selector: 'app-almost-done',
  templateUrl: './almost-done.component.html',
  styleUrls: ['./almost-done.component.css']
})
export class AlmostDoneComponent implements OnInit {

  texts = texts;

  constructor(private scroll:GototopService,
              private footerImg:FooterNavService) { }

  ngOnInit() {
    this.scroll.gotoTop();
  }
}

import { texts } from './jobs-you-are-looking-for.texts';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { LeadService } from '../../services/lead/lead.service';
import { ImageCheck } from '../../models/image-check';
import { GototopService } from '../../services/gototop/gototop.service';
import { FooterNavService } from '../../services/footerNav/footerNav.service';

@Component({
  selector: 'app-jobs-you-are-looking-for',
  templateUrl: './jobs-you-are-looking-for.component.html',
  styleUrls: ['./jobs-you-are-looking-for.component.css']
})
export class JobsYouAreLookingForComponent implements OnInit {
  jobsImages: ImageCheck[] = [];
  footerImages: ImageCheck[] = [];

  texts = texts;

  baseUrl = 'http://private-602e6-topfive.apiary-mock.com';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private lead: LeadService,
              private scroll:GototopService,
              private footerImgs : FooterNavService) {
  }

  ngOnInit() {

    console.log('** ' , this.lead.leadDetails);

    this.route.data.subscribe(( data: { JOBS: Array<ImageCheck> }) => {
      this.jobsImages = data.JOBS;
      this.jobsImages.forEach(job => {
        job.isChecked = false;
        job.isSelected = true;
      });
    });
    this.footerImages = this.footerImgs.getFooterImages();
    this.footerImgs.initFooter();
    this.scroll.gotoTop();
  }

  click = (job: ImageCheck) => {

    this.lead.setJob(job.id, job.hebrewName);
    setTimeout(() => {
      this.router.navigate(['/internships']);
    }, 500);
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Internship } from '../../models/internship';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css']
})
export class CheckBoxComponent implements OnInit {

  // @Output() isChecked = new EventEmitter<boolean>();
  // @Input() isCheck: boolean;

  @Input() onClick: any;
  @Input() internship: Internship;

  checked: boolean;
  constructor() { }

  ngOnInit() {
    this.checked = false;
  }

  check() {
    this.checked = !this.checked;
    this.onClick(this.internship);
  }
}

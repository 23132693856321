import { Component, OnInit } from '@angular/core';
import { texts } from './success.texts';
import { GototopService } from '../../services/gototop/gototop.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  texts = texts;
  constructor(private scroll:GototopService) { }

  ngOnInit() {
    this.scroll.gotoTop();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { CheckBoxComponent } from './check-box/check-box.component';
import { CompanySizeComponent } from './company-size/company-size.component';
import { HeaderComponent } from './header/header.component';
import { JobComponent } from './job/job.component';
import { LocationComponent } from './location/location.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';
import { SocialLoginComponent } from './social-login/social-login.component';
import { YearsExperienceComponent } from './years-experience/years-experience.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { FloorPipe } from './years-experience/floorPipe.component';
import { RouterModule } from '@angular/router';

const COMPONENTS = [
    ButtonComponent,
    CheckBoxComponent,
    CompanySizeComponent,
    HeaderComponent,
    JobComponent,
    LocationComponent,
    NavFooterComponent,
    SocialLoginComponent,
    YearsExperienceComponent,
    FloorPipe
]
@NgModule({
    declarations: [
        ...COMPONENTS
    ],
    exports: [
        ...COMPONENTS
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxBootstrapSliderModule
    ]
})
export class ComponentsModule { }

import { ImageCheck } from './../../models/image-check';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})

export class JobComponent implements OnInit {

  @Input() internship: ImageCheck;
  @Input() Clickable: boolean;
  @Input() onClick: any;
  isClick: boolean;

  constructor() { }

  ngOnInit() {
    this.isClick = this.internship.isChecked;
  }

  buttonClick() {
    if (this.Clickable) {
      this.isClick = !this.isClick;
      this.onClick(this.internship);
    }
  }
}

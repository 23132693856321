import { Injectable } from '@angular/core';
import { Lead } from '../../models/lead';
import { Internship } from '../../models/internship';
import { ImageCheck } from '../../models/image-check';
import { OptionText } from '../../models/option-text';

@Injectable({
    providedIn: 'root',
})
export class LeadService {

    leadDetails: Lead;

    constructor() {
        this.leadDetails = new Lead();
    }


    setJob(id: number, name: string) {
        this.leadDetails.idJob = id;
        this.leadDetails.HebrewJobName = name;
        sessionStorage.setItem('Lead', JSON.stringify(this.leadDetails));
    }

    getJobName() {
        return this.leadDetails.HebrewJobName;
    }

    getJobID() {
        return this.leadDetails.idJob;
    }

    getInternships() {
        return this.leadDetails.internships;
    }

    isCompanySizeEmpty() {
        return this.leadDetails.companySize.length === 0;
    }

    isLocationsEmpty() {
        return this.leadDetails.locations.length === 0;
    }

    setInternships(internships: Internship[]) {
        this.leadDetails.internships = internships;
        this.leadDetails.internships.forEach(internship => {
            internship.isSelected = false;
            internship.onFocus = false;
        });
        sessionStorage.setItem('Lead', JSON.stringify(this.leadDetails));
    }

    setIntershipSelected(internship: Internship) {


        this.leadDetails.internships.forEach(intern => {
            if (intern.id === internship.id) {
                intern.isSelected = !intern.isSelected;
            }
        });
        sessionStorage.setItem('Lead', JSON.stringify(this.leadDetails));
    }

    setInternshipOnfocus(internship: Internship) {

        this.leadDetails.internships.forEach(intern => {
            if (intern.id === internship.id) {
                intern.onFocus = !intern.onFocus;
            }
        });
        sessionStorage.setItem('Lead', JSON.stringify(this.leadDetails));
    }

    setAllInternshipsFocus(internship: Internship, focus: boolean) {

        this.leadDetails.internships.forEach(intern => {
            if (intern.id !== internship.id) {
                intern.onFocus = focus;
            }
        });
        sessionStorage.setItem('Lead', JSON.stringify(this.leadDetails));
    }

    setYearsExperience(internship: Internship, yearsEx: number) {

        this.leadDetails.internships.forEach(intern => {
            if (intern.id === internship.id) {
                intern.yearsExperience = yearsEx;
            }
        });
        sessionStorage.setItem('Lead', JSON.stringify(this.leadDetails));
    }

    getYearsExperience(internship: Internship) {
        return internship.yearsExperience;
    }

    setUserDetails(first: string, last: string, mail: string, phone: string) {
        this.leadDetails.firstName = first;
        this.leadDetails.LastName = last;
        this.leadDetails.Email = mail;
        this.leadDetails.Phone = phone;
    }
    pushPopOption1() {
        console.log("pushPopOption");
    }
    // If the object is in the array then pop him out, otherwise push it
    pushPopOption(option: OptionText) {
        console.log("pushPopOption", option);
        let isPush = true;
        this.leadDetails.options.forEach(opt => {
            if (option.id === opt.id) {
                isPush = false;
            }
        });
        if (isPush) { // push
            console.log("pushPopOption-isPush", isPush);
            this.leadDetails.options.push(option);
        } else { // pop
            const newOptions: OptionText[] = [];
            this.leadDetails.options.forEach(opt => {
                console.log("pushPopOption-options", option);
                if (option.id !== opt.id) {
                    newOptions.push(option);
                }
            });
            this.leadDetails.options = newOptions;
        }
    }

    changeCloseToTrain() {
        this.leadDetails.closeToTrain = !this.leadDetails.closeToTrain;
    }

    setSalary(salary: number) {
        this.leadDetails.salary = salary;
    }

    pushPopCompanySize(size: string) {
        let isPush = true;
        this.leadDetails.companySize.forEach(comp => {
            if (size === comp) {
                isPush = false;
            }
        });
        if (isPush) { // push
            this.leadDetails.companySize.push(size);
        } else { // pop
            const newComp: string[] = [];
            this.leadDetails.companySize.forEach(comp => {
                if (size !== comp) {
                    newComp.push(comp);
                }
            });
            this.leadDetails.companySize = newComp;
        }
    }

    pushPopLocations(location: string) {
        let isPush = true;
        this.leadDetails.locations.forEach(loc => {
            if (location === loc) {
                isPush = false;
            }
        });
        if (isPush) { // push
            this.leadDetails.locations.push(location);
        } else { // pop
            const newComp: string[] = [];
            this.leadDetails.locations.forEach(loc => {
                if (location !== loc) {
                    newComp.push(loc);
                }
            });
            this.leadDetails.locations = newComp;
        }
    }
}

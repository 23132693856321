import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  @Input() text: string;
  @Input() onClick: any;

  isClick: boolean;

  constructor() { }

  ngOnInit() {
    this.isClick = false;
  }

  selectPic() {
    if (this.isClick) {
      return './assets/images/preference-location-white.png';
    } else {
      return './assets/images/preference-location.png';
    }
  }

  click() {
    this.isClick = !this.isClick;
    this.onClick(this.text);
  }

}

export class Internship {
    id: number;
    hebrewName: string;
    englishName: string;
    yearExText: string;
    isSelected: boolean;
    yearsExperience: number;
    onFocus: boolean;


    constructor() {
        this.yearExText = '';
        this.isSelected = false;
        this.yearsExperience = 0;
    }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// API_URL: 'http://localhost:9225/api',
export const environment = {
  production: false,
  API_URL: 'https://gotwish.co.il/api',
  lineOfBusiness: 1,
  maxValueForSalary: 70,
  minValueForSalary: 15,
  startValueForSalary: 30,
  minValueForExperience: 1,
  maxValueForExperience: 10,
  stepsForExperience: 0.1,
  startValueForExperience: 2,
  googleLoginProvider: '350268613435-i78cqsfji05ckdds86t0mdol5ij9jmr6.apps.googleusercontent.com',
  linkedInLoginProvider: '77nykw0nch6560'
};

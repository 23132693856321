import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ImageCheck } from '../../../models/image-check';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../../services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CompanysizeResolverService implements Resolve<any[]> {

  constructor(private http: HttpService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ImageCheck[]> {
    return this.http.makeRequest('GET', `/companysize`)
        .pipe(map( jobs => {
            if (jobs) {
               return jobs;
            } else {
                return null;
            }
        }));
}
}



import { Routes,ExtraOptions, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'



import { JobsResolverService } from './pages/jobs-you-are-looking-for/jobs-resolver/jobs-resolver.service';
import { InternshipsResolverService } from './pages/internships/internships-resolver/internships-resolver.service';
import { PreferenceResolverService } from './pages/preference/preference-resolver/preference-resolver.service';
import { LocationsResolverService } from './pages/preference/locaions-resolver/locations-resolver.service';
import { CompanysizeResolverService } from './pages/preference/companysize-resolver/companysize-resolver.service';


import { WelcomeComponent } from './pages/welcome/welcome.component';
import { WhoWeAreComponent } from './pages/who-we-are/who-we-are.component';
import { JobsYouAreLookingForComponent } from './pages/jobs-you-are-looking-for/jobs-you-are-looking-for.component';
import { InternshipsComponent } from './pages/internships/internships.component';
import { PreferenceComponent } from './pages/preference/preference.component';
import { AlmostDoneComponent } from './pages/almost-done/almost-done.component';
import { FillDetailsComponent } from './pages/fill-details/fill-details.component';
import { SuccessComponent } from './pages/success/success.component';

const routes: Routes = [
    { path: '', 
      component: WelcomeComponent, 
      pathMatch: 'full' },
    { path: 'welcome', 
      component: WelcomeComponent, 
      data: {animation :'WelcomePage'} },
    { path: 'who-we-are', 
      component: WhoWeAreComponent,
      data: {animation: 'WhoWeArePage'} },
    {
      path: 'jobs-you-are-looking-for',
      component: JobsYouAreLookingForComponent,
      resolve: {
        JOBS: JobsResolverService
      },
      data:{
        animation: 'JobsPage'
      }
    },
    {
      path: 'internships',
      component: InternshipsComponent,
      resolve: {
        INTERNSHIPS: InternshipsResolverService
      },
      data:{
        animation: 'InternshipsPage'
      }
    },
    {
      path: 'preference',
      component: PreferenceComponent,
      resolve: {
        OPTIONS: PreferenceResolverService,
        LOCATIONS: LocationsResolverService,
        COMPANYSIZE: CompanysizeResolverService
      },
      data:{
        animation: 'PerferencePage'
      }
    },
    { path: 'almost-done', 
      component: AlmostDoneComponent,
      data:{
        animation: 'AlmostDonePage'
      } 
    },
    { path: 'fill-details', 
      component: FillDetailsComponent,
      data:{
        animation: 'FillDetailsPage'
     } 
    },
    { path: 'success', 
      component: SuccessComponent,
      data:{
        animation: 'SuccessPage'
     }
    }
  ];

  const config: ExtraOptions ={
      useHash:false
  };

  @NgModule({
    imports: [
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, config)
      ],
    exports: [RouterModule],
  })
  export class AppRoutingModule {
  }
import { Internship } from './internship';
import { ImageCheck } from './image-check';
import { environment } from '../../environments/environment';
import { OptionText } from './option-text';

export class Lead {

    firstName: string;
    LastName: string;
    Email: string;
    Phone: string;

    idJob: number;
    HebrewJobName: string;
    internships: Internship[];

    salary: number;
    companySize: string[];
    locations: string[];
    closeToTrain: boolean;
    options: OptionText[];


    constructor() {
        this.idJob = -1;
        this.HebrewJobName = '';
        this.internships = [];
        this.options = [];
        this.closeToTrain = false;
        this.companySize = [];
        this.locations = [];
        this.salary = environment.startValueForSalary;
    }
}

import { Injectable } from '@angular/core';
import { ImageCheck } from '../../models/image-check';
import { texts } from './footerNavigation.text';

@Injectable({
    providedIn: 'root',
})
export class FooterNavService {
    footerImages: ImageCheck[];

    constructor(){
        this.footerImages = [];
        this.setFooterImages();
    }

    setFooterImages()
    {
        const footerImage = new ImageCheck();
        footerImage.id = 0;
        footerImage.imageLink = '/assets/images/footer-job.png';
        footerImage.isChecked = false;
        footerImage.isSelected = true;
        footerImage.hebrewName = texts.role;
        this.footerImages.push(footerImage);
        const footerImage1 = new ImageCheck();
        footerImage1.imageLink = '/assets/images/footer-internship.svg';
        footerImage1.id = 1;
        footerImage1.isChecked = false;
        footerImage1.isSelected = false;
        footerImage1.hebrewName = texts.internship;
        this.footerImages.push(footerImage1);
        const footerImage2 = new ImageCheck();
        footerImage2.imageLink = '/assets/images/footer-preference.svg';
        footerImage2.isChecked = false;
        footerImage2.id = 2;
        footerImage2.isSelected = false;
        footerImage2.hebrewName = texts.prefernce;
        this.footerImages.push(footerImage2);
    }

    getFooterImages()
    {
        return this.footerImages;
    }

    initFooter()
    {
        this.footerImages[0].isChecked = false;
        this.footerImages[0].isSelected = true;
        this.footerImages[1].isChecked = false;
        this.footerImages[1].isSelected = false;
        this.footerImages[2].isChecked = false;
        this.footerImages[2].isSelected = false;
    }

    moveToInternshipPage(){
        this.footerImages[0].isChecked = true;
        this.footerImages[0].isSelected = true;
        this.footerImages[1].isChecked = false;
        this.footerImages[1].isSelected = true;
        this.footerImages[2].isChecked = false;
        this.footerImages[2].isSelected = false;
    }

    moveToPrefencesPage(){
        this.footerImages[0].isChecked = true;
        this.footerImages[0].isSelected = true;
        this.footerImages[1].isChecked = true;
        this.footerImages[1].isSelected = true;
        this.footerImages[2].isChecked = false;
        this.footerImages[2].isSelected = true;
    }
}

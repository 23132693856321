import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { HttpService } from './services/http/http.service';
import { HttpService } from '../../../services/http/http.service';
import { map } from 'rxjs/operators';
import { Internship } from '../../../models/internship';
import { LeadService } from '../../../services/lead/lead.service';

@Injectable({
  providedIn: 'root'
})
export class InternshipsResolverService implements Resolve<any[]> {

  constructor(private http: HttpService,
              private lead : LeadService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Internship[]> {
    this.lead.leadDetails = JSON.parse(sessionStorage.getItem('Lead'));
    let jobid = this.lead.getJobID();
    return this.http.makeRequest('GET', `/internships/${jobid}`)
          .pipe(map( jobs => {
              if (jobs) {
                 return jobs;
              } else {
                  return null;
              }
          }));
  }
}

export const texts = {
    title: `מהן ההעדפות שלך?`,
    next: `הבא`,
    sub_title: [
        `שכר מבוקש`,
        `גודל החברה`,
        `מיקום`,
        `העדפות נוספות:`
    ],
   
    currency: `שקל`,
   
    train: `קרבה לקו רכבת`
};

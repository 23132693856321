import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { texts } from './who-we-are.texts';
import { GototopService } from '../../services/gototop/gototop.service';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent implements OnInit {

  texts = texts;
  constructor(private router: Router,
              private scroll:GototopService) { }

  ngOnInit() {
    this.scroll.gotoTop();
  }
}

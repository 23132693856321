export const texts = {
    title: `כמעט סיימנו!`,
    title_p: [
        `עתה נעבור על הפרטים שמלאת ובמידה ונמצא משרות המותאמות עבורך נשלח לך במייל`,
        `* דיסקרטי לחלוטין וממוקד,`,
        `מייל אחד - 5 משרות מותאמות אישית`,
        `אפשר גם פשוט לשלוח במייל >`
    ],
    sub_title: `להרשמה`,
    button_text: [
        `LinkedIn`,
        `במייל`
    ]
};

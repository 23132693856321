import { texts } from './internships.texts';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { LeadService } from '../../services/lead/lead.service';
import { Location } from '@angular/common';
import { Internship } from '../../models/internship';
import { ImageCheck } from '../../models/image-check';
import { HttpService } from '../../services/http/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GototopService } from '../../services/gototop/gototop.service';
import { environment } from '../../../environments/environment';
import { FooterNavService } from '../../services/footerNav/footerNav.service';

@Component({
  selector: 'app-internships',
  templateUrl: './internships.component.html',
  styleUrls: ['./internships.component.css']
})
export class InternshipsComponent implements OnInit, AfterViewChecked {
  internships: Internship[] = [];
  footerImages: ImageCheck[] = [];

  max = environment.maxValueForExperience;
  min = environment.minValueForExperience;
  startValueForExperience = environment.startValueForExperience;
  texts = texts;
  yearsExTest: string;
  disableNextButton = true;
  disableFocus = true; //to prevent the jump to the choosen internship after the first time.

  constructor(private lead: LeadService,
    private route: ActivatedRoute,
    private scroll: GototopService,
    private footerImg: FooterNavService) {
  }

  public ngAfterViewChecked(): void {
    const elmnt = document.getElementById('yearsExInternship');
    if (elmnt && this.disableFocus) {
      elmnt.scrollIntoView();
      this.disableFocus = false;
    }
  }

  ngOnInit() {

    //this.lead.leadDetails = JSON.parse(sessionStorage.getItem('Lead'));
    this.route.data.subscribe((data: { INTERNSHIPS: Array<Internship> }) => {
      this.internships = data.INTERNSHIPS;
      this.lead.setInternships(this.internships);
    });
    this.footerImages = this.footerImg.getFooterImages();
    this.footerImg.moveToInternshipPage();
    this.scroll.gotoTop();
  }

  click = (internship: Internship) => {
    console.log("internship", internship);
    internship.yearsExperience = 2;
    internship.yearExText = 'שנתיים';
    this.yearsExTest = 'שנתיים';
    if (!internship.onFocus && internship.isSelected) {
      this.lead.setIntershipSelected(internship);
      this.lead.setAllInternshipsFocus(internship, false);
      this.lead.setYearsExperience(internship, null);
    } else {
      if (internship.onFocus && internship.isSelected) {
        this.lead.setYearsExperience(internship, null);
      }
      this.lead.setIntershipSelected(internship);
      this.lead.setInternshipOnfocus(internship);
      this.lead.setAllInternshipsFocus(internship, false);
    }
    console.log("internship-lead", this.lead);
    this.handleDisableNextBtn(internship);
    this.disableFocus = true;
  }

  //Function to prevent click on next button, will be enable when at least 1 internship is selected.
  handleDisableNextBtn(internship: Internship) {
    if (internship.isSelected) {
      this.disableNextButton = false;
    }
    else {
      let internships = this.lead.getInternships();
      let isSelected = internships.find((intenship) => intenship.isSelected);
      this.disableNextButton = !isSelected;
    }
  }




  getOutputValue(yearsEx: number, internship: Internship) {
    if (yearsEx) {
      this.lead.setYearsExperience(internship, yearsEx);
      if (internship.yearsExperience === 1) {
        internship.yearExText = 'שנה';
      } else
        if (internship.yearsExperience === 2) {
          internship.yearExText = 'שנתיים';
        } else
          if (internship.yearsExperience === 10) {
            internship.yearExText = internship.yearsExperience + '+' + ' שנים ';
          } else {
            internship.yearExText = internship.yearsExperience + ' שנים ';
          }
    }
  }
}

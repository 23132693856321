import { Component, OnInit } from '@angular/core';
import { texts } from './social-login.texts';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider, LinkedInLoginProvider } from 'angularx-social-login';
import { LeadService } from '../../services/lead/lead.service';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http/http.service';
import { UsersService } from '../../services/user/user.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.css']
})
export class SocialLoginComponent implements OnInit {

    texts = texts;
    user: SocialUser;
    phone?: string;
    isWhite: boolean;

    isGoogle: boolean;
    isLinkedin: boolean;

    constructor(private authService: AuthService,
        private lead: LeadService,
        private router: Router,
        private userSrv: UsersService,
        private http: HttpService) { }

    ngOnInit() {
        this.authService.authState.subscribe((user) => {
            this.user = user;
            if (this.user !== null && (this.isGoogle || this.isLinkedin)) {
                this.nextPage();
            }
        });
        this.isWhite = false;
        this.isGoogle = false;
        this.isLinkedin = false;
    }

    // signInWithGoogle(): void {
    //     this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    //     this.isGoogle = true;
    //     if (this.user !== null) {
    //         this.nextPage();
    //     }
    // }

    signInWithLinkedIn(): any {
        const promisses = [];
        promisses.push(this.http.post('POST', '/Users/signInLinkedIn', this.lead.leadDetails).toPromise()
            .then(ans => {
                window.location = ans.url;
            })
        );

    }

    signOut(): void {
        this.authService.signOut();
    }

    saveLead() {
        const promisses = [];
        promisses.push(this.http.makeRequest('POST', '/leads', this.lead.leadDetails).toPromise());
        return Promise.all(promisses).then(ans => {
            ans[0]
        });
    }

    nextPage() {
        if (this.user !== null) {
            this.lead.setUserDetails(this.user.firstName, this.user.lastName, this.user.phone, this.user.email);
            this.saveLead();
            this.router.navigate(['/success']);
        }
    }

}

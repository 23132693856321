import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { texts } from './years-experience.texts';
import 'hammerjs';

@Component({
  selector: 'app-years-experience',
  templateUrl: './years-experience.component.html',
  styleUrls: ['./years-experience.component.css']
})
export class YearsExperienceComponent implements OnInit {

  @Input() max: number;
  @Input() min: number;
  @Input() value: number;

  @Output() outputToParent = new EventEmitter<number>();

  text: string;
  title: string;

  constructor() {

   }

  ngOnInit() {
    this.text = '';
    this.title = texts.title;
  }

  change(event) {
    if (event.newValue === 0) {
      this.outputToParent.emit(-1);
    } else {
      this.outputToParent.emit(Math.floor(event.newValue));
    }
  }

  calculateStyles() {
    let dis = 100 / (this.max - this.min) * (this.value - this.min) + 2;
    if(this.value == 10)
    {
      dis -= 3;
    }
    if (this.value == 1)
    {
      dis = 3;
    }
    if (this.value <= 2) {
      this.text = texts.statuses[0];
    } else if (this.value <= 5) {
      this.text = texts.statuses[1];
    } else {
      this.text = texts.statuses[2];
    }
    
    return { 'right' : dis + '%'};
  }
}

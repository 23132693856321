import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { texts } from './fill-details.texts';
import { LeadService } from '../../services/lead/lead.service';
import { HttpService } from '../../services/http/http.service';
import { GototopService } from '../../services/gototop/gototop.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-fill-details',
  templateUrl: './fill-details.component.html',
  styleUrls: ['./fill-details.component.css']
})
export class FillDetailsComponent implements OnInit {

  texts = texts;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  isFocusFirstName: boolean;
  isFocusLastName: boolean;
  isFocusEmail: boolean;
  isFocusPhone: boolean;

  noClickable: boolean;

  leadForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    firstName: new FormControl('', [
      Validators.required
    ]),
    lastName: new FormControl('', [
      Validators.required
    ]),
    phone: new FormControl('', [

    ])
  });

  constructor(private lead: LeadService,
    private router: Router,
    private http: HttpService,
    private scroll: GototopService) { }

  ngOnInit() {
    this.firstName = this.lead.leadDetails.firstName;
    this.lastName = this.lead.leadDetails.LastName;
    this.email = this.lead.leadDetails.Email;
    this.phone = this.lead.leadDetails.Phone;
    if (this.firstName && this.lastName && this.email && this.phone) {
      this.isFocusFirstName = true;
      this.isFocusLastName = true;
      this.isFocusEmail = true;
      this.isFocusPhone = true;
      this.noClickable = false;
    } else {
      this.isFocusFirstName = false;
      this.isFocusLastName = false;
      this.isFocusEmail = false;
      this.isFocusPhone = false;
      this.noClickable = true;
    }
    this.scroll.gotoTop();
  }

  focusFirstName() {
    this.isFocusFirstName = true;
  }

  FocusLastName() {
    this.isFocusLastName = true;
  }

  FocusEmail() {
    this.isFocusEmail = true;
  }

  FocusPhone() {
    this.isFocusPhone = true;
  }

  onChange() {
    if (this.isFocusFirstName && this.isFocusLastName && this.isFocusEmail && this.leadForm.valid) {
      this.noClickable = false;
    }
  }

  saveLead() {
    const promisses = [];
    console.log("saveLead", this.lead.leadDetails)
    promisses.push(this.http.makeRequest('POST', '/leads', this.lead.leadDetails).toPromise());
    return Promise.all(promisses).then(ans => {
      console.log("saveLead-promisses", ans[0])

    });
  }

  onClick() {
    if (this.leadForm.valid && !this.noClickable) {
      let that = this.leadForm;
      this.lead.setUserDetails(this.leadForm.value.firstName, this.leadForm.value.lastName, this.leadForm.value.email, this.leadForm.value.phone);
      this.saveLead();
      this.router.navigate(['/success']);
    }
  }

}

export const texts = {
    title: `GotWish`,
    title_p: `הייטקיסטים, ספרו לנו איזה משרה אתם מחפשים
וקבלו ישירות למייל ובדיסקרטיות 5 הצעות עבודה 
המותאמות אישית עבורך 
`,
    button_text: [
        `מצאו לי משרה!`,
        `רגע, מי אנחנו?`
    ]
};

import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { SocialUser } from "angularx-social-login";

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private httpClient: HttpClient) { }

    

    loginUserLinkedIn(): Observable<any> {
        const headers = new HttpHeaders().append('Access-Control-Allow-Origin', ['*']);

        return this.httpClient.get<any>('/api/Users/signInLinkedIn', { headers });
    }
}
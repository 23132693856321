export const texts = {
    title: `גם מייל סבבה`,
    details: [
        `שם פרטי`,
        `שם משפחה`,
        `אימייל`,
        `טלפון`
    ],
    button_text: `שליחה דיסקרטית`,
    text: `* חשוב לדעת: כל התהליך הינו דיסקרטי לחלוטין. ולא, אנחנו לא הולכים להטריד אותך במיילים נוספים מיותרים. מייל אחד - 5 משרות`,
    emailValidation:`אנא הכנס מייל תקין`
};

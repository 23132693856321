import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-company-size',
  templateUrl: './company-size.component.html',
  styleUrls: ['./company-size.component.css']
})
export class CompanySizeComponent implements OnInit {

  @Input() text: string;
  @Input() onClick: any;

  isClick: boolean;

  constructor() { }

  ngOnInit() {
    this.isClick = false;
    console.log("CompanySizeComponent-ngOnInit: text", this.text);
  }

  selectPic() {
    if (this.isClick) {
      return './assets/images/preference-people-white.png';
    } else {
      return './assets/images/preference-people.png';
    }
  }

  Click() {
    this.isClick = !this.isClick;
    this.onClick(this.text);
  }
}
